@import "./reset.css";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap');


/* font-family: 'DM Sans', sans-serif;
	font-family: 'Poppins', sans-serif; 
*/


:root {
	--black: #000;
	--blue: #248eb8;
	--blue-border: #4399bb;
	--white: #fff;
	--purple: #224e9e;

	--text-color: var(--blue);
	--dark-bg: var(--blue);
	--dark-border: var(--blue-border);
	--header-text: var(--white);
	--accent: var(--purple);

	--page-bg: var(--white);
	--title-1: var(--accent);

	--project-card-bg:var(--white);
	--project-card-text: var(--black);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
}

.dark {
	--page-bg: #182d36;
	--text-color: var(--white);
	--title-1: var(--white);
	--project-card-bg:#183441;
	--project-card-text: var(--white);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.9);
}

html, body {
	
	min-height: 100vh;
	font-family: DM Sans, sans-serif;
	letter-spacing: -0.5px;
	background-color: var(--page-bg);
	color: var(--text-color);
}

#root {
	min-height: 100vh;
}

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.container {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1200px;
	
}









/* BTN outline */

.btn-outline {
	padding: 12px 20px;
	height: 48px;
	border-radius: 5px;
	border: 1px solid var(--blue);
	background-color: var(--white);
	color: var(--blue);
	display: flex;
	column-gap: 10px;
	align-items: center;
	transition: opacity 0.2s ease-in;
}

.btn-outline:hover {
	opacity: 0.8;
}

.btn-outline:active {
	position: relative;
	top: 1px;
}

/* General */

.section {
	padding: 70px 0;
	position: relative;
}

.title-1 {
	margin-bottom: 60px;
	font-size: 60px;
	font-weight: 700;
	line-height: 1.3;
	color: var(--title-1);
	text-align: center;
}

/* Projects */

.projects {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	column-gap: 30px;
	row-gap: 30px;

}

.project {
	min-width: 370px;
	background-color: var(--project-card-bg);
	box-shadow: var(--box-shadow);
	border-radius: 10px;	
}

.project__img {
	border-radius: 10px;
}

.project__title {
	padding: 15px 20px 25px;
	font-weight: 700;
	font-size: 24px;
	line-height: 1.3;
	color: var(--project-card-text);
	
}

/* Project details */

.project-details {
	margin: 0 auto;
	max-width: 865px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.project-details__cover {
	max-width: 100%;
	margin-bottom: 40px;
	background-image: url('../img/projects/05-big.jpg');
	filter: drop-shadow(0px 5px 35px rgba(0, 0, 0, 0.25));
	border-radius: 10px;
}

.project-details__desc {
	margin-bottom: 30px;
	font-weight: 700;
	font-size: 24px;
	line-height: 1.3;
	color: var(--blue);
}

/* Content list */

.title-2 {
	margin-bottom: 20px;
	font-size: 40px;
	line-height: 1.3;
	font-weight: 700;
}

.content-list {
	margin: 0 auto;
	max-width: 570px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	row-gap: 40px;
}

.content-list a {
	color: var(--accent);
}

.content-list__item {
	font-size: 18px;
	line-height: 1.5;
}







@media (max-width: 620px) {
	.nav-row {
		justify-content: space-between;
	}

	.dark-mode-btn {
		order: 0;
	}

	.header {
		min-height: unset;
	}
	.header__title {
		font-size: 30px;
	}
	.header__title strong {
		font-size: 40px;
	}
	.section {
		padding: 40px 0;
	}
	.title-1 {
		margin-bottom: 30px;
		font-size: 40px;
	}
	.footer {
		padding: 40px 0 30px;
		margin-top: 0;
	}
	img {
		max-width: 100%;
	}
	
}

