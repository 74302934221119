/* Footer */

.footer {
	margin-top: auto;
	padding: 60px 0 50px;
	background-color: var(--dark-bg);
	color: var(--header-text);
}

.footer__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 27px;
}

.social {
	display: flex;
	column-gap: 30px;
	align-items: center;
}

.copiright {
	font-size: 16px;
}