/* Dark mode btn */

.dark-mode-btn {
	padding: 5px;
	display: flex;
	justify-content: space-between;
	width: 51px;
	height: 26px;
	border-radius: 50px;
	position: relative;
	background: #224e9e;
	order: 7;
}

.dark-mode-btn::before {
	content: '';
	display: block;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	position: absolute;
	top: 1px;
	left: 1px;
	background-color: #fff;
	transition: left 0.2s ease-in;
}

.dark-mode-btn--active::before {
	left: 26px;
}

.dark-mode-btn__icon {
	position: relative;
	z-index: 2;
}